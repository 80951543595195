body {
  background-color: black;
  background-image: url("../img/arahira.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
}

.content {

  text-align: center;

  p.notice {
    color: red;
    line-height: 0;
    font-family: 'BenchNine', sans-serif;
    font-size: 18px;
    transition: all $transitiontime linear;
  }

  .feature-button .copy-contain {
    span.copy {
      height: $featureHeightS;
      font-size: $featureCopyFont;
    }
    span.tech {
      width: $featureWidth;
      font-size: $featureTechFont;
    }
    a i, a span i {
      font-size: $featureGhIcon;
    }
  }

  .link-button .copy-contain {
    span.copy {
      height: $linkHeightS;
      font-size: $linkCopyFont;
    }
    span.tech {
      width: $linkWidth;
      font-size: $linkTechFont;
    }
    a i, a span i {
      font-size: $linkGhIcon;
    }
  }

  .contrib-button .copy-contain {
    text-align: center;

    span.copy {
      height: $contribHeightS;
      font-size: $contribCopyFont;
    }
    span.tech {
      width: $contribWidth;
      font-size: $contribTechFont;
    }
    a i, a span i {
      font-size: $contribGhIcon;
    }
  }

  .copy-contain {
    position: relative;
    display: inline-block;
    // img {
    //   min-height: 100%;
    // }
    span.copy {
      background: rgba(0,0,0,$tint);
      color: white;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      display: table;
      opacity: 0;
      transition: opacity $tintTime;
    }
    span.copy span {
      font-family: 'BenchNine', sans-serif;
      opacity: 1;
      display: table-cell;
      vertical-align: top;
      padding: 20px;
      transition: all $transitiontime linear;
    }
    a:hover span.copy, a:first-child:hover + a span.tech, a:hover span.tech, {
      opacity: 1;
    }
    a i {
      color: $ghIconBlue;
      position: absolute;
      bottom: 7px;
      left: 7px;
      transition: all $transitiontime linear;
    }

    span.tech {
      //background: rgba(0,0,0,0.5);
      opacity: 0;
      color: $techCopyBlue;
      font-family: 'BenchNine', sans-serif;
      position: absolute;
      bottom: 5px;
      left: 0;
      transition: opacity $tintTime;

      span {
        transition: all $transitiontime linear;
      }
    }
  }

  .top-buttons, .bottom-buttons {
    display: inline-block;

    .copy-contain {

      vertical-align: middle;
      transform: translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      backface-visibility: hidden;
      transition-duration: 0.3s;
      transition-property: transform;

      img {
        border: 1px solid $darkerblue;
        border-radius: 2px;
        transition: all $transitiontime linear;
        //box-shadow: -1px 1px 4px 0px;
      }
    }

    // no feature-button here; it's block.
    .feature-button, .link-button, .contrib-button {
      display: inline-block;
    }

    .feature-button {
      margin: 10px 0px 60px;
      img {
        width: $featureWidth;
        height: $featureHeight;
      }
    }

    .link-button {
      margin: 0 15px 15px;
      img {
        width: $linkWidth;
        height: $linkHeight;
      }
    }

    .contrib-button {
      margin: 0 15px;
      img {
        width: $contribWidth;
        height: $contribHeight;
      }
    }

    .copy-contain:hover, .copy-contain:focus, .copy-contain:active {
      transform: scale($scaleRatio);
    }
  }
}

.bottom-half {
  text-align: center;

    .separate {
      margin: 10px 0 28px;
      display: block;
      color: $darkerblue;
      font-size: $separateFontSize;
      font-family: 'Passion One', cursive;
      transition: all $transitiontime linear;
  }
}
