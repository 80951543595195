.topnav {
  font-family: 'Roboto', sans-serif;
  ul {
    padding-left: 5px;
    list-style-type: none;
    margin: 0;
    -webkit-padding-start: 0;
    -moz-padding-start: 0;

    li {
      display: inline-block;
      p, span {
        transition: all $transitiontime linear;
      }
    }
  }

  ul.left {
    margin: 5px 0 0;
    float: left;
    color: white;

    li.leftSub {
      margin-left: 5px;
    }

    ul.leftSub {
      display: inline;
      font-size: $cwFontSize;

      li {
        display: block;
        p {
          margin: 0;
        }
      }
    }

    li.cwlogo img {
      height: $cwLogoHeight;
      width: $cwLogoWidth;
      display: inline;
    }
  }

  ul.right {
    float: right;
    margin: 5px 0 55px;

    .link-icon:hover li, .link-icon:focus li, .link-icon:active li, {
      transform: translateY($headerIconUp);
      /* move the element up by 5px */
    }

    .link-icon:hover p, .link-icon p:hover {
      opacity: 1;
    }

    .link-icon {
      display: inline-block;
      text-align: center;
      transition: all $transitiontime linear;
      p {
        position: relative;
        bottom: $headerIconFontShiftUp;
        opacity: 0;
        margin: 0;
        font-size: $headerIconFontSize;
        transition: opacity $textUnderIconsTransition cubic-bezier(0,.33,.18,1);
        a {
          color: white;
          text-decoration: none;
          transition: color $transitiontime linear;
        }
      }
    }

    li {
      margin: 0 5px;
      vertical-align: middle;
      transform: translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      transition-duration: 0.3s;
      transition-property: transform;

      i {
        color: white;
        transition: all $transitiontime linear;
      }
    }
  }
}

.headerPart{
  clear: both;
  text-align: center;
  margin: 30px 0 10px;

  img {
    height: 120px;
    width: 120px;
    border-radius: 2px;
    border: 1px solid black;
    position: relative;
    cursor: pointer;

    transition: all $transitiontime linear;
  }

  h1 {
    font-family: 'Passion One', cursive;
    color: $darkerblue;
    font-size: 100px;
    margin: 0;
    transition: all $transitiontime linear;
  }
}

.hidden {
  visibility: hidden;
}
