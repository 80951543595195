// ----- iPhone ---- //
@media only screen and (min-device-width : 320px) and (max-device-width : 767px)  {
  .content {

    p.notice {
      font-size: 14px;
    }

    .feature-button .copy-contain {
      span.copy {
        display: none;
      }
      span.tech {
        width: 300px;
        opacity: 1;
        color: white;
        font-size: 24px;
      }
      a i, a span i {
        font-size: $linkGhIcon;
      }
    }

    .link-button .copy-contain {
      span.copy {
        display: none;
      }
      span.tech {
        width: 300px;
        opacity: 1;
        color: white;
        font-size: 14px;
      }
    }

    .contrib-button .copy-contain {
      span.copy {
        display: none;
      }
      span.tech {
        width: 200px;
        opacity: 1;
        color: white;
        font-size: 10px;
      }
    }

    .top-buttons, .bottom-buttons {

      .feature-button {
        margin: 0;
        img {
          width: 300px;
          height: 150px;
        }
      }

      .link-button {
        margin: 0;
        img {
          width: 300px;
          height: 150px;
        }
      }

      .contrib-button {
        margin: 0 12px;
        img {
          width: 200px;
          height: 100px;
        }
      }

    }
  }

  .bottom-half {
    text-align: center;
    margin: 20px 0 20px;
    .separate {
      font-size: 34px;
    }
  }

  // ------- header section -------
  .topnav {
    ul {
      text-align: center;
    }

    ul.left {
      display: none;
    }

    ul.right {
      float: none;
      margin: 20px 0;

      .link-icon {
        font-size: 16px;
      }
      .link-icon:last-child {
        display: none;
      }

    }

  }

  .headerPart{
    clear: both;
    text-align: center;
    margin: 0 0 30px;

    h1 {
      font-size: 72px;
    }

    img {
      height: 70px;
      width: 70px;
    }
  }

}
