$transitiontime: 3s;
$darkerblue: #00D;
$ghIconBlue: #00D;
$techCopyBlue: #33F;


 // S refers to the height to use for the shadow
 // such that it extends all the way to the bottom of the
 // image.
$featureWidth: 550px;
$featureHeight: 275px;
$featureHeightS: 276px;

$featureCopyFont: 26px;
$featureTechFont: 30px;
$featureGhIcon: 42px;

$linkWidth: 350px;
$linkHeight: 175px;
$linkHeightS: 176px;

$linkCopyFont: 20px;
$linkTechFont: 20px;
$linkGhIcon: 26px;

$contribWidth: 280px;
$contribHeight: 140px;
$contribHeightS: 141px;

$contribCopyFont: 18px;
$contribTechFont: 18px;
$contribGhIcon: 22px;

$cwFontSize: 15px;
$cwLogoHeight: 66px;
$cwLogoWidth: 58px;

$tint: 0.80;
$tintTime: 500ms;

$scaleRatio: 1.05;

$separateFontSize: 52px;

$headerIconUp: -13px;
$headerIconFontSize: 10px;
$headerIconFontShiftUp: 14px;
$textUnderIconsTransition: 300ms;
